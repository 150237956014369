import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BButton } from '@/components/bootstrap'
import { QuizAnswer, QuizQuestion } from '@/store/courseStore'
import { showError } from '@/helpers/notifications'
import { courseStore } from '@/store'
import './style.scoped.scss'
import FormInput from '@/components/controls/form/FormInput'

@Component
export default class QuizAnswerElement extends tsx.Component<{
  question: QuizQuestion
  answer: QuizAnswer
  index: number
}> {
  @Prop({ type: Object, required: true })
  readonly question!: QuizQuestion
  @Prop({ type: Object, required: true })
  readonly answer!: QuizAnswer
  @Prop({ type: Number, required: true })
  readonly index!: number

  remove() {
    if (this.question.answers.length === 2) {
      showError('В вопросе не может быть меньше двух ответов')
      return
    }

    courseStore.removeAnswer({ question: this.question, answer: this.answer })
  }

  protected render() {
    return (
      <div class="answer rounded shadow mb-1 p-1 drag-handle">
        <BButton
          onClick={this.remove}
          variant="flat-danger"
          class="delete-answer-button p-50"
        >
          <feather-icon icon="TrashIcon" />
        </BButton>
        <FormInput label="Текст" fieldValidator={this.answer.text} />
        <FormInput
          label="Баллы"
          fieldValidator={this.answer.points}
          type="number"
        />
      </div>
    )
  }
}
