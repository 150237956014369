import { Component, Prop, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'
import { BButton } from '@/components/bootstrap'
import { QuizQuestion } from '@/store/courseStore'
import CollapseTransition from '@/components/transitions/Collapse'
import { courseStore, i18nStore } from '@/store'
import FormInput from '@/components/controls/form/FormInput'
import Switcher from '@/components/controls/Switcher'
import { showError } from '@/helpers/notifications'
import QuizAnswerElement from './Answer'
import Draggable from '@/components/Draggable'

@Component
export default class QuizQuestionElement extends tsx.Component<{
  question: QuizQuestion
  index: number
}> {
  @Prop({ type: Object, required: true })
  readonly question!: QuizQuestion
  @Prop({ type: Number, required: true })
  readonly index!: number

  @Watch('question.isAnswers')
  protected onIsAnswersChanged(value: boolean) {
    if (!value) {
      this.question.answers.forEach(a => {
        a.text.reset()
        a.points.reset()
      })
    }
  }

  private removeQuestion(e: Event) {
    e.stopPropagation()

    if (courseStore.quizQuestions.length === 1) {
      showError('В квизе должен быть как минимум один вопрос')
      return
    }

    courseStore.removeQuestion(this.question)
  }

  protected render() {
    return (
      <div class="question rounded shadow mb-1">
        <div
          onClick={() => (this.question.collapsed = !this.question.collapsed)}
          class="d-flex align-items-center"
        >
          <BButton variant="flat-secondary" class="p-50">
            <feather-icon
              icon="ChevronUpIcon"
              class={['transition', { 'rotate-180': this.question.collapsed }]}
            />
          </BButton>
          <span class="drag-handle flex-grow-1 px-50 py-25">{`#${this.index}. ${
            this.question.title.value[i18nStore.selectedLanguage]
          }`}</span>
          <BButton
            variant="flat-danger"
            onClick={this.removeQuestion}
            class="p-50"
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
        </div>

        <CollapseTransition hidden>
          {!this.question.collapsed && (
            <div>
              <div class="p-1">
                <FormInput
                  label="Вопрос"
                  fieldValidator={this.question.title}
                />
                <FormInput
                  label="Id поля в Amo"
                  fieldValidator={this.question.amoId}
                  type="number"
                />
                <FormInput
                  label="Примечание к вопросу"
                  fieldValidator={this.question.description}
                />
                <div class="d-flex align-items-center mb-1">
                  <Switcher
                    switcherId={`questions-answers-check-${this.question.id}`}
                    value={this.question.isAnswers}
                    onInput={value => {
                      this.question.isAnswers = value
                    }}
                    scopedSlots={{
                      leftIcon: () => <feather-icon icon="MenuIcon" />,
                      rightIcon: () => <feather-icon icon="TypeIcon" />,
                    }}
                  />
                  <label
                    class="m-0 mx-50"
                    for={`questions-answers-check-${this.question.id}`}
                  >
                    Поле ввода / выбор ответа
                  </label>
                </div>
                {this.question.isAnswers && [
                  <Switcher
                    value={this.question.twoColumns}
                    onInput={value => (this.question.twoColumns = value)}
                    class="mb-50"
                  >
                    Две колонки
                  </Switcher>,
                  <Draggable
                    value={this.question.answers}
                    onInput={value => (this.question.answers = value)}
                    scopedSlots={{
                      item: ({ item, index }) => (
                        <QuizAnswerElement
                          question={this.question}
                          answer={item}
                          index={index + 1}
                          key={item.id}
                        />
                      ),
                    }}
                  />,
                  <BButton
                    onClick={() =>
                      courseStore.addAnswer({ question: this.question })
                    }
                    variant="outline-primary"
                  >
                    Добавить ответ
                  </BButton>,
                ]}
              </div>
            </div>
          )}
        </CollapseTransition>
      </div>
    )
  }
}
