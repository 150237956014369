import { IVmodelProps } from '@/interfaces/IVmodelProps'
import { Component, VModel, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class PdfLoader extends tsx.Component<
  { label: string } & IVmodelProps<string | File | null>
> {
  @VModel()
  pdf!: string | File | null

  @Prop({ type: String, required: true })
  readonly label!: string

  get pdfFile() {
    if (typeof this.pdf !== 'string') {
      return this.pdf
    }
  }

  get pdfUrl() {
    if (typeof this.pdf === 'string') {
      return this.pdf
    }
  }

  protected render() {
    return (
      <div>
        <label>{`${this.label}:`}</label>
        {this.pdfUrl && (
          <p class="mb-0">
            <a href={this.pdfUrl} target="_blank">
              Ссылка на загруженный файл
            </a>
          </p>
        )}
        <div class="d-flex flex-row">
          <b-form-file
            value={this.pdfFile}
            onInput={(value: File) => {
              if (value) {
                this.pdf = value
              }
            }}
            browse-text={this.pdfFile === null ? 'Выбор' : 'Обновить'}
            placeholder=".pdf"
            drop-placeholder="Перетащите сюда..."
            accept=".pdf"
          />
          <b-button
            onClick={() => (this.pdf = null)}
            variant="flat-secondary"
            class="btn-icon p-0 ml-1"
            v-b-tooltip="Очистить файл"
          >
            <feather-icon size="20" icon="Trash2Icon" />
          </b-button>
        </div>
      </div>
    )
  }
}
