import Draggable from '@/components/Draggable'
import {
  BButton,
  BCard,
  BCardTitle,
  BCol,
  BFormGroup,
  BRow,
} from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import { courseStore } from '@/store'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import QuizQuestion from './Question'
import ImageUploader from '@/components/controls/ImageUploader'
import Switcher from '@/components/controls/Switcher'

@Component
export default class QuizTab extends tsx.Component<{}> {
  public mounted() {
    if (!courseStore.quizQuestions.length) {
      courseStore.addQuestion()
    }
  }

  protected render() {
    return (
      <BCard title="Настройки">
        <Switcher
          value={courseStore.quizEnabled}
          onInput={value => (courseStore.quizEnabled = value)}
          class="mb-2"
        >
          Активен
        </Switcher>

        <BRow>
          <BCol sm={4}>
            <FormInput
              label="Желтый"
              fieldValidator={courseStore.yellowPoints}
              type="number"
            />
          </BCol>
          <BCol sm={4}>
            <FormInput
              label="Зеленый"
              fieldValidator={courseStore.greenPoints}
              type="number"
            />
          </BCol>
          <BCol sm={4}>
            <FormInput
              label="Золотой"
              fieldValidator={courseStore.goldPoints}
              type="number"
            />
          </BCol>
        </BRow>

        <FormInput
          label="Название файла"
          fieldValidator={courseStore.quizFileName}
        />
        <BFormGroup
          label="Изображение"
          invalid-feedback={courseStore.quizImage.errorText}
          state={courseStore.quizImage.hasError ? false : null}
        >
          <ImageUploader
            value={courseStore.quizImage.value}
            onInput={courseStore.quizImage.set}
            state={courseStore.quizImage.hasError ? false : null}
            width="150px"
          />
        </BFormGroup>

        <BCardTitle class="mt-3">Вопросы</BCardTitle>
        <BButton
          onClick={() => courseStore.addQuestion()}
          variant="outline-primary"
          class="mb-1"
        >
          Добавить вопрос
        </BButton>
        <Draggable
          value={courseStore.quizQuestions}
          onInput={value => (courseStore.quizQuestions = value)}
          scopedSlots={{
            item: ({ item, index }) => (
              <QuizQuestion question={item} index={index + 1} key={item.id} />
            ),
          }}
        />
      </BCard>
    )
  }
}
