import { Component, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BButton, BOverlay, BTab, BTabs } from '@/components/bootstrap'
import { courseStore } from '@/store'
import Switcher from '@/components/controls/Switcher'
import MainTab from '@/components/courseEdit/MainTab'
import QuizTab from '@/components/courseEdit/QuizTab'
import LoaderOverlay, { Task } from '@/components/LoaderOverlay'
import { showError } from '@/helpers/notifications'
import { uploadImage } from '@/helpers/upload'

@Component
export default class CourseEditPage extends tsx.Component<{}> {
  @Ref() readonly overlay!: LoaderOverlay

  private async saveCourse(close = true) {
    let result = await courseStore.validate()

    if (!result) {
      showError('При заполнении полей допущены ошибки')
      return
    }

    result = await this.overlay.show(this.getLoadingTasks())

    if (!result) {
      return
    }

    if (close) {
      this.$router.push('/courses')
    } else if (courseStore.courseState === 'new') {
      history.replaceState(
        null,
        '',
        location.href.replace(
          'courses/add',
          `courses/edit/${courseStore.courseId}`,
        ),
      )
    }
  }

  private getLoadingTasks(): Task[] {
    const tasks: Task[] = []

    if (courseStore.quizImage.value instanceof File) {
      tasks.push({
        title: `Загрузка превью изображения ${courseStore.quizImage.value.name}`,
        action: async () => {
          const url = await uploadImage(
            courseStore.quizImage.value as File,
            'blog/preview',
          )
          if (url) {
            courseStore.quizImage.set(url)
            return true
          }
          return false
        },
      })
    }

    if (courseStore.courseState === 'new') {
      tasks.push({
        title: 'Создание курса',
        action: courseStore.saveCourse,
      })
    } else {
      tasks.push({
        title: 'Сохранение курса',
        action: courseStore.saveCourse,
      })
    }

    return tasks
  }

  protected render() {
    return (
      <LoaderOverlay ref="overlay">
        <div class="position-relative">
          <div class="mb-1 d-flex flex-wrap justify-content-between">
            <div class="d-flex">
              <BButton
                onClick={() => this.saveCourse(false)}
                variant="primary"
                class="mr-1"
              >
                {courseStore.courseState === 'new'
                  ? 'Создать курс'
                  : 'Сохранить курс'}
              </BButton>

              <BButton
                onClick={() => this.saveCourse()}
                variant="outline-primary"
              >
                {courseStore.courseState === 'new'
                  ? 'Создать и закрыть'
                  : 'Сохранить и закрыть'}
              </BButton>
            </div>

            <Switcher
              value={courseStore.enabled}
              onInput={value => (courseStore.enabled = value)}
            >
              Курс открыт
            </Switcher>
          </div>
          <BTabs>
            <BTab
              title="Основные данные"
              title-link-class={
                courseStore.mainValidator.hasErrors ? 'text-danger' : null
              }
            >
              <MainTab />
            </BTab>
            <BTab
              title="Квиз"
              disabled={!courseStore.hasQuiz}
              title-link-class={
                courseStore.quizValidator.hasErrors ? 'text-danger' : null
              }
            >
              <QuizTab />
            </BTab>
          </BTabs>
        </div>
      </LoaderOverlay>
    )
  }
}
