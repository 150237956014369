import Select from '@/components/controls/Select'
import UserSelectTemplate from '@/components/controls/Select/templates/User'
import FormInput from '@/components/controls/form/FormInput'
import CollapseTransition from '@/components/transitions/Collapse'
import FadeTransition from '@/components/transitions/Fade'
import { courseStore } from '@/store'
import { Mentor, Teacher, Tag } from '@/store/courseStore'
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BFormRadioGroup,
  BButton,
} from '@/components/bootstrap'
import { Component, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import CourseImage from './CourseImage'
import PdfLoader from './PdfLoader'
import TagsModal from './TagsModal'
import { isDarkColor } from '@/helpers/color'
import { CourseType } from '@/api/courses'
import Switcher from '@/components/controls/Switcher'

@Component
export default class MainTab extends tsx.Component<{}> {
  @Ref() readonly tagsModal!: TagsModal

  private courseTypes: { text: string; value: CourseType }[] = [
    {
      text: 'Бесплатный',
      value: 'free',
    },
    {
      text: 'Платный',
      value: 'paid',
    },
  ]

  private renderTag(tag: Tag) {
    return (
      <b-badge style={{ background: tag.color }} class="m-25">
        <h5
          class={['m-0', isDarkColor(tag.color) ? 'text-white' : 'text-dark']}
        >
          {tag.label}
        </h5>
      </b-badge>
    )
  }

  protected render() {
    return (
      <BRow>
        <BCol md={12} lg={7}>
          <BCard title="Основная информация">
            <FormInput
              label="Название курса"
              fieldValidator={courseStore.title}
            />
            <FormInput label="Alias" fieldValidator={courseStore.alias} />
            <FormInput
              label="Aббревиатура"
              fieldValidator={courseStore.abbreviation}
            />
            <FormInput
              label="Описание курса"
              fieldValidator={courseStore.description}
              textarea
              rows={4}
            />

            <BRow>
              <BCol sm={6}>
                <FormInput
                  label="Кол-во свободных мест"
                  fieldValidator={courseStore.freePlaces}
                  type="number"
                />
              </BCol>
              <BCol sm={6}>
                <FormInput
                  label="Кол-во работ в портфолио"
                  fieldValidator={courseStore.portfolioCount}
                  type="number"
                />
              </BCol>
            </BRow>

            <BRow>
              <BCol sm={6}>
                <FormInput
                  label="Порог прохождения для выдачи сертификата"
                  fieldValidator={courseStore.certificateRate}
                  type="number"
                />
              </BCol>
              <BCol sm={6}>
                {courseStore.courseType.value === 'free' && (
                  <FadeTransition>
                    <FormInput
                      label="Балы челленжа"
                      fieldValidator={courseStore.challengePoints}
                      type="number"
                    />
                  </FadeTransition>
                )}
              </BCol>
            </BRow>

            {courseStore.courseType.value === 'free' && (
              <Switcher
                value={courseStore.hasQuiz}
                onInput={value => (courseStore.hasQuiz = value)}
                class="mb-2"
              >
                Квиз
              </Switcher>
            )}

            <BCardTitle>Тип курса</BCardTitle>
            <BFormRadioGroup
              checked={courseStore.courseType.value}
              onchange={courseStore.courseType.set}
              options={this.courseTypes}
              class="mb-3"
            />

            <BCardTitle>Время обучения</BCardTitle>
            <BRow>
              <BCol>
                <Select
                  value={courseStore.learnTimeValue}
                  onInput={value => (courseStore.learnTimeValue = value)}
                  options={courseStore.learnTimeValues}
                  clearable={false}
                />
              </BCol>
              <BCol>
                <Select
                  value={courseStore.learnTimeType}
                  onInput={value => (courseStore.learnTimeType = value)}
                  options={courseStore.learnTimeTypes}
                  clearable={false}
                />
              </BCol>
            </BRow>
          </BCard>

          <BCard>
            <BRow>
              <BCol sm={6}>
                <BCardTitle>Изображение курса</BCardTitle>
                <CourseImage
                  value={courseStore.courseImage.value}
                  onInput={courseStore.courseImage.set}
                  error={courseStore.courseImage.errorText}
                />
              </BCol>
              <BCol sm={6}>
                <BCardTitle>PDF шаблоны диплома</BCardTitle>
                <PdfLoader
                  label="Русский"
                  value={courseStore.ruPdf}
                  onInput={value => (courseStore.ruPdf = value)}
                />
                <PdfLoader
                  label="Украинский"
                  value={courseStore.uaPdf}
                  onInput={value => (courseStore.uaPdf = value)}
                />
                <PdfLoader
                  label="Английский"
                  value={courseStore.enPdf}
                  onInput={value => (courseStore.enPdf = value)}
                />
              </BCol>
            </BRow>
          </BCard>
        </BCol>

        <BCol md={12} lg={5}>
          <BCard title="Наставники">
            <div class="mb-2">
              <Select
                value={courseStore.selectedMentors.value}
                onInput={courseStore.selectedMentors.set}
                options={courseStore.mentors}
                label="fullName"
                elementDeclination={['ментор', 'ментора', 'менторов']}
                placeholder="Выберите менторов"
                state={courseStore.selectedMentors.hasError ? false : null}
                scopedSlots={{
                  option: ({ option }: { option: Mentor }) => (
                    <UserSelectTemplate
                      name={option.fullName}
                      avatar={option.avatar}
                    />
                  ),
                }}
              />
              <small class="text-danger">
                {courseStore.selectedMentors.errorText}
              </small>
            </div>

            <BCardTitle>Преподаватель</BCardTitle>
            <Select
              value={courseStore.selectedTeacher.value}
              onInput={courseStore.selectedTeacher.set}
              options={courseStore.teachers}
              label="fullName"
              placeholder="Выберите преподавателя"
              state={courseStore.selectedTeacher.hasError ? false : null}
              scopedSlots={{
                selectedOption: (option: Teacher) => (
                  <UserSelectTemplate
                    name={option.fullName}
                    avatar={option.avatar_url}
                    size="sm"
                  />
                ),
                option: ({ option }: { option: Teacher }) => (
                  <UserSelectTemplate
                    name={option.fullName}
                    avatar={option.avatar_url}
                  />
                ),
              }}
            />
            <small class="text-danger">
              {courseStore.selectedTeacher.errorText}
            </small>
          </BCard>

          <BCard title="Выбор тега">
            <div>
              <div class="mb-1">
                <Select
                  value={courseStore.selectedTag.value}
                  onInput={courseStore.selectedTag.set}
                  options={courseStore.tags}
                  clearable={false}
                  placeholder="Выберите тег"
                  state={courseStore.selectedTag.hasError ? false : null}
                  scopedSlots={{
                    selectedOption: (option: Tag) => this.renderTag(option),
                    option: ({ option }: { option: Tag }) =>
                      this.renderTag(option),
                  }}
                />
                <small class="text-danger">
                  {courseStore.selectedTag.errorText}
                </small>
              </div>
              <BButton
                variant="outline-primary"
                onClick={() => this.tagsModal.show()}
              >
                Управление тегами
              </BButton>
            </div>
          </BCard>

          <BCard title="SEO">
            <FormInput
              label="Заголовок"
              fieldValidator={courseStore.seoTitle}
            />
            <FormInput
              label="Описание"
              fieldValidator={courseStore.seoDescription}
              textarea
              rows={3}
            />
          </BCard>

          <CollapseTransition>
            {courseStore.courseType.value === 'paid' && (
              <BCard title="Оплата" class="overflow-hidden">
                <Switcher
                  value={courseStore.isPurchaseAllowed}
                  onInput={value => (courseStore.isPurchaseAllowed = value)}
                  class="mb-1"
                >
                  Можно купить, даже если закрыт
                </Switcher>
                <FormInput
                  label="Цена ($)"
                  fieldValidator={courseStore.price}
                  type="number"
                />
                <FormInput
                  label="Предоплата ($)"
                  fieldValidator={courseStore.prepayment}
                  type="number"
                />
                <FormInput
                  label="Скидка"
                  fieldValidator={courseStore.discount}
                  type="number"
                />
              </BCard>
            )}
          </CollapseTransition>
        </BCol>
        <TagsModal ref="tagsModal" onSelect={courseStore.selectedTag.set} />
      </BRow>
    )
  }
}
