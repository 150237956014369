import { BModal, BOverlay, BButton } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import { isDarkColor } from '@/helpers/color'
import { showError } from '@/helpers/notifications'
import { FieldValidator, notEmpty } from '@/helpers/validation'
import axios from '@/libs/axios'
import { emptyLocalizedField, LocalizedField } from '@/store/i18nStore'
import { AxiosResponse } from 'axios'
import { BvEvent } from 'bootstrap-vue'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import ColorPicker from '@/components/controls/ColorPicker'

@Component
export default class TagModal extends tsx.Component<{
  onClose: () => void
}> {
  modalId = 'course-tag-modal'
  pending = false

  state: 'new' | 'edit' = 'new'

  label = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    localized: true,
  })
  color = '#7367F0'
  id = -1

  get modalTitle() {
    if (this.state === 'new') {
      return 'Создать тег'
    }
    return 'Редактировать тег'
  }

  get okButtonTitle() {
    if (this.state === 'new') {
      return 'Создать'
    }
    return 'Сохранить'
  }

  async show(id?: number) {
    this.$bvModal.show(this.modalId)
    this.pending = true

    this.state = id ? 'edit' : 'new'

    if (id) {
      this.id = id

      try {
        const response: AxiosResponse<{
          color: string
          id: number
          label: LocalizedField
        }> = await axios.get(`v2/course/tags/${id}`)
        const label = emptyLocalizedField()
        Object.assign(label, response.data.label)

        this.color = response.data.color
        this.label.set(label)
      } catch (error) {
        showError('При загрузке тега произошла ошибка')
        console.error(error)
        this.$bvModal.hide(this.modalId)
      }
    }

    this.pending = false
  }

  async submit(e: BvEvent) {
    e.preventDefault()

    if (!this.label.validate()) {
      return
    }
    this.pending = true

    try {
      if (this.state === 'new') {
        await axios.post('v2/course/tags', {
          label: this.label.value,
          color: this.color,
        })
      } else {
        await axios.patch(`v2/course/tags/${this.id}`, {
          label: this.label.value,
          color: this.color,
        })
      }

      this.$bvModal.hide(this.modalId)
      this.$emit('close')
    } catch (error) {
      showError('При сохранении тега произошла ошибка')
      console.error(error)
    }

    this.pending = false
  }

  onHidden() {
    this.id = -1
    this.label.set(emptyLocalizedField())
    this.label.reset()
    this.pending = false
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        title={this.modalTitle}
        ok-title={this.okButtonTitle}
        cancel-title="Отмена"
        busy={this.pending}
        onHidden={this.onHidden}
        onOk={this.submit}
      >
        <BOverlay show={this.pending} no-wrap />
        <div class="d-flex">
          <FormInput
            placeholder="Тег"
            fieldValidator={this.label}
            class="mb-0 mx-1 flex-grow-1"
          />
          <ColorPicker
            value={this.color}
            onInput={value => (this.color = value)}
            style={{ marginTop: '4px' }}
          />
        </div>
      </BModal>
    )
  }
}
