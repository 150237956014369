export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function isDarkColor(hex: string) {
  const rgbColor = hexToRgb(hex)
  return rgbColor!.r * 0.8 + rgbColor!.g + rgbColor!.b * 0.2 < 255
}
