import { Component, Ref, VModel } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import iro from '@jaames/iro'
import { IVmodelProps } from '@/interfaces/IVmodelProps'
import './style.scoped.scss'

@Component
export default class ColorPicker extends tsx.Component<IVmodelProps<string>> {
  @Ref() readonly colorPicker!: HTMLElement
  @Ref() readonly sliders!: HTMLElement

  @VModel({ type: String, required: true })
  color!: string

  tooltipDelay = 500
  tempColor = '#ffffff'
  validColor = ''
  open = false
  direction: 'right' | 'left' = 'right'
  sliderPicker: any = null
  initialized = false
  inputValid: false | null | true = null

  openPicker() {
    this.open = true
    this.inputValid = null
    this.tempColor = this.color
    this.validColor = this.color
    if (!this.initialized) {
      this.init()
    }
    this.sliderPicker.color.hexString = this.tempColor || '#ffffff'
  }

  closePicker() {
    this.open = false
  }

  handleInput(value: string) {
    if (value.length != 7) {
      this.inputValid = false
      return
    }
    try {
      this.sliderPicker!.color.hexString = value
      this.validColor = value
      this.inputValid = null
    } catch {
      this.inputValid = false
    }
  }

  checkPosition() {
    const right = this.colorPicker.getBoundingClientRect().right

    this.direction =
      right + 240 < document.documentElement.clientWidth ? 'right' : 'left'
  }

  selectColor() {
    this.color = this.validColor
    this.closePicker()
  }

  init() {
    this.checkPosition()
    const sliderPicker = new (iro.ColorPicker as any)(this.sliders, {
      width: this.sliders.clientWidth,
      color: 'rgb(255, 0, 0)',
      borderWidth: 1,
      borderColor: '#3b4253',
      margin: 2,
      handleRadius: 4,
      layout: [
        {
          component: iro.ui.Slider,
          options: {
            sliderType: 'hue',
          },
        },
        {
          component: iro.ui.Slider,
          options: {
            sliderType: 'value',
          },
        },
        {
          component: iro.ui.Slider,
          options: {
            sliderType: 'saturation',
          },
        },
      ],
    })
    sliderPicker.on(['color:change'], (color: { hexString: string }) => {
      this.tempColor = color.hexString
      this.validColor = color.hexString
    })

    this.sliderPicker = sliderPicker
    this.initialized = true
  }

  mounted() {
    window.addEventListener('resize', this.checkPosition)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.checkPosition)
  }

  protected render() {
    return (
      <div
        class="color-picker"
        ref="colorPicker"
        v-click-outside={() => this.closePicker()}
      >
        <div
          onClick={this.openPicker}
          class="color-circle"
          style={{ background: this.color }}
        />
        <div
          class={[
            'picker-popup',
            { 'is-open': this.open },
            { 'popup-left': this.direction === 'left' },
          ]}
        >
          <div
            class={[
              'popup-row',
              { 'flex-row-reverse': this.direction === 'left' },
            ]}
          >
            <div
              onClick={this.closePicker}
              class="color-circle"
              style={{ background: this.validColor }}
            ></div>
            <b-form-input
              value={this.tempColor}
              onInput={(value: string) => {
                this.tempColor = value
                this.handleInput(value)
              }}
              type="text"
              class="mx-1"
              state={this.inputValid}
            />
            <b-button
              onClick={this.selectColor}
              variant="flat-success"
              class="btn-icon p-0"
            >
              <feather-icon size="20" icon="CheckIcon" />
            </b-button>
          </div>
          <div class="sliders" ref="sliders"></div>
        </div>
      </div>
    )
  }
}
