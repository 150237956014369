import FormInput from '@/components/controls/form/FormInput'
import { Component, Prop, VModel, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

export type CourseImageObject = {
  color: string
  url?: string | null
  preview?: string | null
  file?: File | null
}

interface ICourseImageProps {
  value: CourseImageObject
  onInput: (value: CourseImageObject) => void
  error?: string | null
}

@Component
export default class CourseImage extends tsx.Component<ICourseImageProps> {
  @Ref() readonly imageInput!: HTMLInputElement

  @VModel()
  courseImage!: CourseImageObject
  @Prop({ type: String })
  readonly error?: string

  get imageSrc() {
    return this.courseImage.preview || this.courseImage.url
  }

  handleImageUpload() {
    const uploadedFile = this.imageInput.files![0]
    if (!uploadedFile) {
      return
    }
    this.courseImage.file = uploadedFile
    this.setImagePreview(uploadedFile)
    this.courseImage.url = null
    this.courseImage = this.courseImage
  }

  setImagePreview(file: File) {
    if (/\.(jpe?g|png|gif|svg)$/i.test(file.name)) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.courseImage.preview = reader.result as string
        },
        false,
      )
      reader.readAsDataURL(file)
    }
  }

  protected render() {
    return (
      <div>
        <FormInput
          label="Цвет фона:"
          value={this.courseImage.color}
          onInput={value => (this.courseImage.color = value)}
        />
        <div
          class={[
            'image-preview',
            'rounded-lg',
            'mb-1',
            'mb-sm-0',
            'd-flex',
            'align-items-center',
            'justify-content-center',
            { 'border-danger': !!this.error },
          ]}
          style={{ background: this.courseImage.color }}
        >
          <input
            type="file"
            class="hidden"
            ref="imageInput"
            accept=".jpg, .jpeg, .png, .svg"
            onChange={this.handleImageUpload}
          />
          {this.imageSrc ? (
            <img src={this.imageSrc} alt="" class="course-image" />
          ) : (
            <feather-icon size="40" icon="UploadIcon" />
          )}
          <div
            class="image-upload d-flex align-items-center justify-content-center"
            onClick={() => this.imageInput.click()}
          >
            <feather-icon size="40" icon="UploadIcon" />
          </div>
        </div>
        <small class="text-danger">{this.error}</small>
      </div>
    )
  }
}
