import { BModal, BOverlay, BButton } from '@/components/bootstrap'
import { isDarkColor } from '@/helpers/color'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { courseStore } from '@/store'
import { Tag } from '@/store/courseStore'
import { Component, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import TagModal from './TagModal'

@Component
export default class TagsModal extends tsx.Component<{
  onSelect: (tag: Tag) => void
}> {
  @Ref() readonly tagModal!: TagModal

  modalId = 'course-tags-modal'
  pending = false

  get tags() {
    return courseStore.tags
  }

  async show() {
    this.$bvModal.show(this.modalId)
  }

  async removeTag(id: number) {
    const result = await this.$bvModal.msgBoxConfirm(
      `Вы действительно хотите удалить тег?`,
      {
        title: 'Подтверждение удаления',
        centered: true,
        okTitle: 'Удалить',
        cancelTitle: 'Отмена',
      },
    )

    if (!result) {
      return
    }

    this.pending = true
    try {
      await axios.delete(`v2/course/tags/${id}`)
      courseStore.tags = this.tags.filter(t => t.id !== id)
    } catch (error) {
      console.error(error)
      showError('При удалении тега произошла ошибка')
    }
    this.pending = false
  }

  select(tag: Tag) {
    this.$emit('select', tag)
    this.$bvModal.hide(this.modalId)
  }

  async refresh() {
    this.pending = true
    await courseStore.fetchTags()
    this.pending = false
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        hide-footer
        centered
        title="Теги курса"
        busy={this.pending}
      >
        <BOverlay show={this.pending} no-wrap />

        <BButton
          variant="primary"
          class="mb-1"
          onClick={() => this.tagModal.show()}
        >
          Создать тег
        </BButton>

        {this.tags.map(tag => (
          <div class="d-flex align-items-center mb-50">
            <div
              class={[
                'p-50',
                'rounded',
                'cursor-pointer',
                'flex-grow-1',
                isDarkColor(tag.color) ? 'text-white' : 'text-dark',
              ]}
              style={{ background: tag.color }}
              onClick={() => this.select(tag)}
            >
              {tag.label}
            </div>
            <BButton
              onClick={() => this.tagModal.show(tag.id)}
              variant="primary"
              class="mx-50 p-50"
            >
              <feather-icon icon="EditIcon" size="16" />
            </BButton>
            <BButton
              onClick={() => this.removeTag(tag.id)}
              variant="danger"
              class="p-50"
            >
              <feather-icon icon="TrashIcon" size="16" />
            </BButton>
          </div>
        ))}
        <TagModal ref="tagModal" onClose={this.refresh} />
      </BModal>
    )
  }
}
